import React, { FunctionComponent } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

import { rhythm } from '../utils/typography';

const query = graphql`
  query WhoAmIQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;


const WhoAmI: FunctionComponent = () => {
  const data = useStaticQuery(query);

  const { author, social } = data.site.siteMetadata;

  return (
    <div style={{
      display: 'flex',
      padding: 10,
      width: '100%',
      maxWidth: 1200,
      marginTop: 20,
      borderRadius: 10,
      margin: '0 auto',
      backgroundColor: '#ececec',
      alignItems: 'flex-start',
    }}>
      <GatsbyImage
        image={data.avatar.childImageSharp.gatsbyImageData}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          maxWidth: 64,
          maxHeight: 64,
          minWidth: 64,
        }}
        imgStyle={{
          borderRadius: '10px',
        }}
      />
      <div>
        Bienvenue ! J'écris sur le développement, l'artisanat logiciel et les bons outils. Je partage aussi du contenu sur <a href="https://mailchi.mp/e88b54098776/vinceops" title="Newsletter" rel="noopener" target="_nlank">la newsletter</a> et sur <a href={`https://twitter.com/${social.twitter}`} title={social.twitter} rel="noopener" target="_blank">Twitter</a>. Bonne lecture.
      </div>
    </div>
  );
};

export default WhoAmI;