import React, { FunctionComponent } from 'react';

import { graphql } from 'gatsby';

import ArticleList from '../components/ArticleList';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import WhoAmI from '../components/WhoAmI';

export type BlogIndexProps = {
  data?: any;
  location: Location;
};

const BlogIndex: FunctionComponent<BlogIndexProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Blog d'un passionné"
        keywords={['typescript', 'nestjs', 'nest', 'nodejs', 'react', 'rust', 'webassembly']}
      />
      <WhoAmI />
      <ArticleList nodes={posts} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 230)
          fields {
            slug
            tags
          }
          timeToRead
          frontmatter {
            date(formatString: "DD MMM YYYY")
            title
            timeEmoji
            emojiRatio
            ogimage {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, JPG]
                )
              }
            }
          }
        }
      }
    }
  }
`;
